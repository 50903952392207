import { useState, useEffect } from "react";
import { collection, getDocs, getFirestore } from "firebase/firestore";
const useUsers = () => {
  const db = getFirestore();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { users, loading, error };
};
export default useUsers;

const AdminUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = () => children;

  useEffect(() => {
    if (!currentUser) {
      // Handle user creation or authentication
    }
  }, []);

  return (
    <div>
      {children}
    </div>
  );
};

export { AdminUserProvider };
