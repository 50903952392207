import React from "react";

function Contact(props) {
  return (
    <div className="container my-5">
      <h2 className="text-center fw-bold mb-4">Contact Us</h2>
      <div className="card shadow p-4">
        <div className="row">
          {/* Contact Details */}
          <div className="col-md-6">
            <h4 className="fw-bold">📍 Address</h4>
            <p className="fs-5 mb-3">P.O. Box 170, Matsapha, M202, eSwatini</p>

            <h4 className="fw-bold">📞 Phone</h4>
            <p className="fs-5">
              <a
                href="tel:+26825184555"
                className="text-dark text-decoration-none fw-bold"
              >
                +268 2518 4555
              </a>
            </p>

            <h4 className="fw-bold">📧 Emails</h4>
            <p className="fs-5 mb-1">
              <a
                href="mailto:reception@swazitrac.co.sz"
                className="text-dark fw-bold"
              >
                reception@swazitrac.co.sz
              </a>
            </p>
            <p className="fs-5 mb-1">
              <a
                href="mailto:sales@swazitrac.co.sz"
                className="text-dark fw-bold"
              >
                sales@swazitrac.co.sz
              </a>
            </p>
            <p className="fs-5 mb-1">
              <a
                href="mailto:workshop@swazitrac.co.sz"
                className="text-dark fw-bold"
              >
                workshop@swazitrac.co.sz
              </a>
            </p>
            <p className="fs-5 mb-3">
              <a
                href="mailto:parts@swazitrac.co.sz"
                className="text-dark fw-bold"
              >
                parts@swazitrac.co.sz
              </a>
            </p>

            <h4 className="fw-bold">⏰ Opening Hours</h4>
            <p className="fs-5 mb-1">
              Monday - Friday:{" "}
              <span className="fw-bold">7:00 AM - 4:30 PM</span>
            </p>
            <p className="fs-5 mb-1">
              Saturday: <span className="fw-bold text-danger">Closed</span>
            </p>
            <p className="fs-5">
              Sunday & Public Holidays:{" "}
              <span className="fw-bold text-danger">Closed</span>
            </p>
          </div>

          {/* Google Maps Embed */}
          <div className="col-md-6">
            <h4 className="fw-bold">📌 Find Us on Maps</h4>
            <div className="ratio ratio-16x9">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3570.5546973514874!2d31.318907199999998!3d-26.502279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1eef4972626a1ca1%3A0x389adf13c814995c!2sSwaziTrac%20Pty%20Ltd.%20John%20Deere%20Dealer%2C%20Eswatini!5e0!3m2!1sen!2sza!4v1740643256682!5m2!1sen!2sza"
                width="600"
                height="500"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
