import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './config/firebaseConfig'; // Ensure your Firebase config is imported
import { initializeApp } from 'firebase/app';
import FirebaseProvider from './components/FirebaseProvider';
import firebaseConfig from './config/firebaseConfig';
import { getAuth } from 'firebase/auth';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
