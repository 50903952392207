import { Link } from "react-router-dom";

const suppliers = [
  { name: "John Deere", logo: "../images/johndeere-logo.png", link: "/products/agTractors" },
  { name: "Falcon", logo: "../images/falcon-logo.png", link: "/products/falcon" },
  { name: "Rovic", logo: "../images/rovic-logo-jpeg_idpt.webp", link: "/products/rovic" },
  { name: "MoSpare", logo: "../images/mospare-logo.png", link: "/products/mospare" },
];

const SupplierRibbon = () => {
  return (
    <section id="suppliers" className="container-fluid mb-3">
      <div className="full-width-green top-rounded text-center py-3">Our Suppliers</div>
      <div className="supplier-ribbon flex flex-wrap justify-center gap-4 p-4">
        {suppliers.map((supplier, idx) => (
          <div key={idx} className="supplier w-32 h-32 flex items-center justify-center rounded-lg shadow-md bg-white">
            <Link to={supplier.link} className="flex items-center justify-center">
              <img src={supplier.logo} alt={supplier.name} className="max-w-full max-h-full" />
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SupplierRibbon;
