import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ContactUs from "./pages/ContactUs";
import About from "./pages/AboutPage";
import Support from "./pages/support";
import Product from "./pages/Product";
import Agriculture from "./pages/agriculture";
import Construction from "./pages/construction";
import Forestry from "./pages/forestry";
import Turf from "./pages/turf";
import LawnGarden from "./pages/lawngarden";
import Tractors from "./pages/agTractors";
import Falcon from "./pages/falcon";
import Rovic from "./pages/rovic";
import Mospare from "./pages/mospare";
import PrecisionAg from "./pages/PrecisionAg";
import Home from "./pages/HomePage";
import AgImplements from "./pages/agImplements";
import AgHarvesters from "./pages/agHarvesters";
import AdminPage from "./components/AdminPage";
import LoginPage from "./components/Login";
import FirebaseProvider from "./components/FirebaseProvider";


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { useAuth } from './hooks/useAuth';

const App = () => {  

  return (
    <Router>
      <nav className="header">
        {/* Your header or navigation component */}
      </nav>

      <main>
        <Routes>
          {/* Home route */}
          <Route path="/home" element={<Home />} />

          {/* Products routes */}
          <Route
            path="/products"
            element={<Product />}
            children={
              <>
                {/* Product categories */}
                <Route path="lawn-garden" element={<LawnGarden />} />
                <Route path="turf" element={<Turf />} />
                <Route path="construction" element={<Construction />} />
                <Route path="forestry" element={<Forestry />} />
                <Route path="agTractors" element={<Tractors />} />
                <Route path="agHarvesters" element={<AgHarvesters />} />
                <Route path="agImplements" element={<AgImplements />} />
                <Route path="PrecisionAg" element={<PrecisionAg />} />
                <Route path="falcon" element={<Falcon />} />
                <Route path="rovic" element={<Rovic />} />
                <Route path="mospare" element={<Mospare />} />
              </>
            }
          />

          {/* Service route */}
          <Route
            path="/service"
            element={<Support />}
            children={
              <>
                <Route path="sales-support" element={<Support />} />
              </>
            }
          />

          {/* News, About, Contact, and Login routes */}
          <Route path="/news" element={<Product />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/" element={<Home />} /> {/* Catch-all route */}
        </Routes>
      </main>
    </Router>
  );
};

export default App;
