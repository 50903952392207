import { experimentalSetDeliveryMetricsExportedToBigQueryEnabled } from "firebase/messaging/sw";
import react from "react";
import ProductGrid from "./productGrid";
import ProductList from "../components/ProductList";
import ProductShowcase from "../components/ProductShowcase";
import SupplierRibbon from "../components/SupplierRibbon";

function AgImplements() {
  return (
    <main class="container my-5">
      <section class="mb-5 ">
        <div class="head-img container-fluid">
          <img
            src="/images/n540f.avif"
            class="img-fluid border-top-deere"
            alt="Image"
          />
          <p>
            John Deere: A Legacy of Power, Precision, and Performance in
            Agriculture.
          </p>
        </div>

        <div class="">
          <p>
            Boost Your Farm’s Potential with John Deere Agricultural Implements
            Maximize efficiency, productivity, and performance with John Deere’s
            industry-leading agricultural implements. 
            Engineered for durability
            and precision, our equipment is designed to help you plant,
            cultivate, and harvest with ease—no matter the scale of your
            operation. 
            <br />
            
            Cutting-edge technology for precision farming 
            Built tough
            for reliability in all conditions 
            Optimized for efficiency to save
            time and fuel 
            <br />
            From plows and seeders to balers and sprayers, John
            Deere delivers the tools you need to grow your success. Invest in
            quality, increase your yield, and take your farm to the next level.
            Contact us today to find the right equipment for your needs!
          </p>
          <br></br>
          <p>Explore our wide variety of agricultural implements</p>
        </div>

        <h1>Agricultural Implements</h1>
        <div class="container-fluid text-center">
          <ProductGrid products={implementsProducts} />
        </div>

        <SupplierRibbon />

        <h1>Product Showcase</h1>
        <ProductShowcase products={implementsProducts} />

        <br />

        {/* Display Products as names and links */}
        <ProductList data={implementsProducts} />
      </section>
    </main>
  );
}

export default AgImplements; // export

// Here Lies a list of products

const implementsProducts = [
  {
    id: 1,
    name: "975 Reversible Plow",
    image: "/images/975rp.avif",
    link: "https://www.deere.africa/en/tillage/primary-tillage/975-reversible-plow/",
  },
  {
    id: 2,
    name: "995 Reversible Plow",
    image: "/images/995rp.jpg",
    link: "https://www.deere.africa/en/tillage/primary-tillage/995-reversible-plow/",
  },
  {
    id: 3,
    name: "913 V-Ripper",
    image: "/images/913vr.avif",
    link: "https://www.deere.africa/en/tillage/primary-tillage/913-v-ripper/",
  },
  {
    id: 4,
    name: "915 V-Ripper",
    image: "/images/915vr.jpg",
    link: "https://www.deere.africa/en/tillage/primary-tillage/915-v-ripper/",
  },
  {
    id: 5,
    name: "2700 Mulch Ripper",
    image: "/images/2700mr.avif",
    link: "https://www.deere.africa/en/tillage/primary-tillage/2700-mulch-ripper/",
  },
  {
    id: 6,
    name: "2730 Combination Ripper",
    image: "/images/2730cr.webp",
    link: "https://www.deere.africa/en/tillage/primary-tillage/2730-combination-ripper/",
  },
  {
    id: 7,
    name: "2430 Chisel Plow",
    image: "/images/2430cp.jpg",
    link: "https://www.deere.africa/en/tillage/primary-tillage/2430-chisel-plow/",
  },
  {
    id: 8,
    name: "225 Offset Disk",
    image: "/images/225od.jpg",
    link: "https://www.deere.africa/en/tillage/disks/225-offset-disk/",
  },
  {
    id: 9,
    name: "425 Offset Disk",
    image: "/images/425od.avif",
    link: "https://www.deere.africa/en/tillage/disks/425-offset-disk/",
  },
  {
    id: 10,
    name: "670 Single Offset Disk",
    image: "/images/670od.avif",
    link: "https://www.deere.africa/en/tillage/disks/670-single-offset-disks/",
  },
  {
    //drawn planters
    id: 11,
    cat: "Drawn Planter",
    name: "1015 Drawn Planter",
    image: "/images/1015dp.webp",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1015-drawn-planter/",
  },
  {
    id: 12,
    cat: "Drawn Planter",
    name: "1755 Drawn Planter",
    image: "/images/1755dp.webp",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1755-planter/",
  },
  {
    id: 13,
    cat: "Drawn Planter",
    name: "1775 Flex Drawn Planter",
    image: "/images/1775.webp",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1775-flex/",
  },
  {
    id: 14,
    cat: "Drawn Planter",
    name: "1775NT 12 Row 30 Drawn Planter",
    image: "/images/1775np.avif",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1775nt-12-row-30/",
  },
  {
    id: 15,
    cat: "Drawn Planter",
    name: "1775NT 16 Row 30 Drawn Planter",
    image: "/images/1775np.avif",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1775nt-16row-30/",
  },
  {
    id: 16,
    cat: "Drawn Planter",
    name: "1775NT 24 Row 30 Drawn Planter",
    image: "/images/1775np.avif",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1175nt-24row-30/",
  },
  {
    id: 17,
    cat: "Drawn Planter",
    name: "1795 12 Row Split 23 or 24 Drawn Planter",
    image: "/images/1795.jpg",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1795-12row-split-23-24/",
  },
  {
    id: 18,
    cat: "Drawn Planter",
    name: "1795 16 Row Split 31 or 32 Drawn Planter",
    image: "/images/1795.jpg",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1795-16row-split-31-32/",
  },
  {
    id: 19,
    cat: "Drawn Planter",
    name: "1795 24 Row 20 Drawn Planter",
    image: "/images/1795.jpg",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1795-24row-20/",
  },
  {
    id: 20,
    cat: "Drawn Planter",
    name: "1100 Drawn Planter",
    image: "/images/1100.webp",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/1100/",
  },
  {
    id: 21,
    cat: "Drawn Planter",
    name: "2100 Drawn Planter",
    image: "/images/1100.webp",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/2100/",
  },
  {
    //integral planters
    id: 22,
    cat: "Integral Planter",
    name: "1705 Planter",
    image: "/images/1705.jpg",
    link: "https://www.deere.africa/en/planting-equipment/intergral-planters/1705-planter/",
  },
  {
    id: 23,
    cat: "Integral Planter",
    name: "1725 Integral Stack - fold Planter",
    image: "/images/1725is.avif",
    link: "https://www.deere.africa/en/planting-equipment/intergral-planters/1725-intergral-stack-fold/",
  },
  {
    id: 24,
    cat: "Integral Planter",
    name: "1725 CCS Stack - Fold Planter",
    image: "/images/1725css.avif",
    link: "https://www.deere.africa/en/planting-equipment/intergral-planters/1725-ccs-stack-fold/",
  },
  {
    id: 25,
    cat: "Integral Planter",
    name: "1010 Planter",
    image: "/images/1010p.webp",
    link: "https://www.deere.africa/en/planting-equipment/intergral-planters/1010-planter/",
  },
  {
    id: 26,
    cat: "Drawn Planter",
    name: "2100 Drawn Planter",
    image: "/images/1100.webp",
    link: "https://www.deere.africa/en/planting-equipment/drawn-planters/2100/",
  },
  {
    //Seedeers
    id: 27,
    cat: "Seeder",
    name: "1590 No-till Drill",
    image: "/images/1590ntd.webp",
    link: "https://www.deere.africa/en/seeding-equipment/1590-no-till-drill/",
  },
  {
    id: 28,
    cat: "Seeder",
    name: "BD11 Series End Wheel Grain Drills",
    image: "/images/bd11.avif",
    link: "https://www.deere.africa/en/seeding-equipment/bd11-series-end-wheel-grain-drill/",
  },
  {
    id: 29,
    cat: "Seeder",
    name: "N530F Separate Fertilizer Placement (SFP) Air Drill",
    image: "/images/n530f.avif",
    link: "https://www.deere.africa/en/seeding-equipment/n530f-separate-fertilizer-placement-air-drill/",
  },
  {
    id: 30,
    cat: "Seeder",
    name: "N540F Separate Fertilizer Placement (SFP) Air Drill",
    image: "/images/n540f.avif",
    link: "https://www.deere.africa/en/seeding-equipment/n540f-separate-fertilizer-placement-air-drill/",
  },
  {
    id: 31,
    cat: "Seeder",
    name: "N543F Separate Fertilizer Placement (SFP) Air Drill",
    image: "/images/n540f.avif",
    link: "https://www.deere.africa/en/seeding-equipment/n543f-separate-fertilizer-placement-air-drill/",
  },
  {
    id: 32,
    cat: "Seeder",
    name: "N560F Separate Fertilizer Placement (SFP) Air Drill",
    image: "/images/n540f.avif",
    link: "https://www.deere.africa/en/seeding-equipment/n560f-separate-fertilizer-placement-air-drill/",
  },
  {
    id: 33,
    cat: "Seeder",
    name: "N530C Air DrillCentral Commodity System (CCS™)",
    image: "/images/n530c.avif",
    link: "https://www.deere.africa/en/seeding-equipment/n530c-central-commodity-system-ccs/",
  },
  {
    id: 34,
    cat: "Seeder",
    name: "N536C Air DrillCentral Commodity System (CCS™)",
    image: "/images/n530c.avif",
    link: "https://www.deere.africa/en/seeding-equipment/n536c-central-commodity-system-ccs/",
  },
  {
    id: 35,
    cat: "Seeder",
    name: "N540C Air DrillCentral Commodity System (CCS™)",
    image: "/images/n530c.avif",
    link: "https://www.deere.africa/en/seeding-equipment/n540c-central-commodity-system-ccs/",
  },
  {
    id: 36,
    cat: "Seeder",
    name: "N542C Air DrillCentral Commodity System (CCS™)",
    image: "/images/n530c.avif",
    link: "https://www.deere.africa/en/seeding-equipment/n542c-central-commodity-system-ccs/",
  },
  {
    id: 37,
    cat: "Cart",
    name: "1910 Commodity Cart",
    image: "/images/1910.avif",
    link: "https://www.deere.africa/en/seeding-equipment/1910-air-commodity-cart/",
  },
  {
    id: 38,
    cat: "Sprayer",
    name: "R4030 Self Propelled Sprayer",
    image: "/images/r4030.webp",
    link: "https://www.deere.africa/en/application-equipment/",
  },
  {
    id: 39,
    cat: "Sprayer",
    name: "R4038 Self Propelled Sprayer",
    image: "/images/r4030.webp",
    link: "https://www.deere.africa/en/application-equipment/",
  },
  {
    id: 40,
    cat: "Sprayer",
    name: "R4045 Self Propelled Sprayer",
    image: "/images/r4030.webp",
    link: "https://www.deere.africa/en/application-equipment/",
  },
];
