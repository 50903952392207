// src/pages/home.js
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../templates_/Layout";
import Slider from "../components/Slider";

const prodImages = [
  { Title: "Tractors", url: "./images/tractor2.avif", link: "products/agTractors" },
  { Title: "AgImplements", url: "./images/425od.avif", link: "products/agImplements" },
  { Title: "Cane Harvesters", url: "./images/s7600.avif", link: "products/agHarvesters" },
  { Title: "Lawn & Garden", url: "./images/mower.avif", link: "products/lawngarden" },
  { Title: "Turf", url: "./images/gator.avif", link: "products/forestry" },
  { Title: "Forestry", url: "./images/forestry/1470G_1366x768_large.avif", link: "products/forestry" },
  { Title: "Construction", url: "./images/850j_ii_dozer.webp", link: "products/construction" },
];

const suppliers = [
  { name: "John Deere", logo: "images/johndeere-logo.png", link: "/products/agTractors" },
  { name: "Falcon", logo: "images/falcon-logo.png", link: "/products/falcon" },
  { name: "Rovic", logo: "images/rovic-logo-jpeg_idpt.webp", link: "/products/rovic" },
  { name: "MoSpare", logo: "images/mospare-logo.png", link: "/products/mospare" },
];

const HomePage = () => {
  return (
    <main className="container my-5">
      <section className="mb-5 text-center">
        <img src="../images/r1.jpg" className="img-fluid" alt="Agricultural Machinery" />
        <h1 className="my-3">SwaziTrac Agriculture Products</h1>
        <p>SwaziTrac offers a wide range of agricultural machinery and equipment, designed to meet the needs of the local agricultural sector.</p>
        
        <Slider prodimg={prodImages} />
      </section>

      <div className="container-fluid align-middle head-img">
        <img src="../images/r3.jpg" className="img-fluid" alt="SwaziTrac Overview" />
        <p className="centered">
          SwaziTrac is the official John Deere dealership in Eswatini, distributing agricultural and construction equipment across the country.
        </p>
      </div>

      <section id="news" className="container-fluid mb-3">
        <div className="full-width-green top-rounded mb-3">News</div>
        <div className="row row-cols-2 row-cols-md-3">
          <div className="col-lg-4 col-md-6">
            <div className="card shadow-sm">
              <div className="card-img-overlay text-center">
                <h3 className="overlay-title">Title</h3>
              </div>
              <img src="./images/460p_adt_r4j018797_large.avif" className="card-img-top img-fluid" alt="News Image" />
              <div className="card-body">
                <p className="card-text">text body</p>
                <p className="card-footer text-muted">
                  <a href="#" className="btn btn-primary btn-news">Read More</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="container-fluid mb-3">
        <div className="full-width-green top-rounded">About Swazi Trac</div>
        <div className="img-fluid mb-3">
          <img src="images/full-marketing-1.jpg" className="img-fluid" alt="SwaziTrac Overview" />
        </div>
        <p>
          Established in 1975, Swazi-Trac has grown into Eswatini's largest supplier of agricultural and construction machinery. We provide superior customer service and high-quality machinery for various sectors.
        </p>
        {/* Rest of the content */}
      </section>

      <section id="suppliers" className="container-fluid mb-3">
        <div className="full-width-green top-rounded text-center py-3">Our Suppliers</div>
        <div className="supplier-ribbon">
          {suppliers.map((supplier, idx) => (
            <div key={idx} className="supplier">
              <Link to={supplier.link}>
                <img src={supplier.logo} alt={supplier.name} />
              </Link>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

const Home = () => (
  <Layout>
    <HomePage />
  </Layout>
);

export default Home;
