import { experimentalSetDeliveryMetricsExportedToBigQueryEnabled } from 'firebase/messaging/sw';
import React from 'react';
import ProductGrid from './productGrid';
import ProductList from '../components/ProductList';


const rovicProducts_bale = [
  {
    id: 1,
    cat: "Rake",
    name: "3 Point Finger Wheel Rakes",
    image: "/images/3-pt-finger-wheel-rake.webp",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/rakes-tedders/3-point-finger-wheel-rakes",
  },
  {
    id: 2,
    cat: "Rake",
    name: "Trailed Speed Rakes",
    image: "/images/trailed-speed-rake.webp",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/rakes-tedders/trailed-speed-rake",
  },
  {
    id: 3,
    cat: "Rake",
    name: "Triple Disc Mower Conditioners",
    image: "/images/triple-disk-mower-conditioners.webp",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage",
  },
  {
    id: 4,
    cat: "Rake",
    name: "Trailed Mower Conditioners",
    image: "/images/trailed-mower-conditioners.jpg",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/mowers/trailed-mower-conditioners",
  },
  {
    id: 5,
    cat: "Mower",
    name: "3 Point Drum Mowers",
    image: "/images/3-point-drum-mowers.jpg",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/mowers/3-point-drum-mowers",
  },
  {
    id: 6,
    cat: "Mower",
    name: "3 Point Disk Mowers",
    image: "/images/3-point-disc-mowers.webp",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/mowers/3-point-disc-mowers",
  },
  {
    id: 7,
    cat: "Belt Merger",
    name: " Loader Silage Equipment ",
    image: "/images/beltmerger.webp",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/merger/belt-merger",
  },
  {
    id: 8,
    cat: " Loader Silage Equipment ",
    name: "Silage Loader Equipment ",
    image: "/images/LoaderSilageEquipment.jpg",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/loader-silage-equipment",
  },
  {
    id: 9,
    cat: "GyroTedder",
    name: "Gyrotedder",
    image: "/images/gyrotedder.jpg",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/rakes-tedders/gyrotedder",
  },
  {
    id: 10,
    cat: "Rake",
    name: "Gyrorakes",
    image: "/images/gyrorakes.jpg",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/rakes-tedders/gyrorakes",
  },
  {
    id: 11,
    cat: "Rake",
    name: "Kuhn Gyrorake and Tedder Combo",
    image: "/images/gyrotedder.jpg",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/rakes-tedders/kuhn-gyrorake-and-tedder-combo",
  },
  {
    id: 12,
    cat: "Conditioner",
    name: "Front Mounted Mower Conditioner",
    image: "/images/front-mounted-mower-conditioners.webp",
    link: "https://www.rovic.com/agricultural-machinery/haymaking-silage/mowers/front-mounted-mower-conditioner",
  }

  
  // EOB
];

const rovicProducts_baler = [
  {
    id: 1,
    cat: "Pack",
    name: "Big Pack HDP 1290",
    image: "/images/big-pack-hdp.webp",
    link: "https://www.rovic.com/agricultural-machinery/balers-and-bale-handling/big-pack-balers/big-pack-hdp-1290",
  },
  {
    id: 2,
    cat: "Balers",
    name: "Fortima Round Balers",
    image: "/images/fortima-round-balers.jpg",
    link: "https://www.rovic.com/agricultural-machinery/balers-and-bale-handling/round-balers/fortima-round-balers",
  },
  {
    id: 3,
    cat: "Balers",
    name: "Small Square Balers",
    image: "/images/small-square-baler.webp",
    link: "https://www.rovic.com/red-line/small-square-balers",
  },
  {
    id: 4,
    cat: "Pack",
    name: "Big Pack 1270",
    image: "/images/bipack1270.jpg",
    link: "https://www.rovic.com/agricultural-machinery/balers-and-bale-handling/big-pack-balers/big-pack-1270",
  },
  {
    id: 5,
    cat: "Balers",
    name: "Vari-pack Round Balers",
    image: "/images/round-balers-varipack.jpg",
    link: "https://www.rovic.com/agricultural-machinery/balers-and-bale-handling/round-balers/vari-pack-round-balers",
  }
  ,
  {
    id: 6,
    cat: "Balers",
    name: "Bellima Round Balers",
    image: "/images/bellima-round-balers.webp",
    link: "https://www.rovic.com/agricultural-machinery/balers-and-bale-handling/round-balers/bellima-round-balers",
  }
  ,
  {
    id: 7,
    cat: "Balers",
    name: "Comprima Round Balers",
    image: "/images/comprima.webp",
    link: "https://www.rovic.com/agricultural-machinery/balers-and-bale-handling/round-balers/comprima-round-balers",
  }
  ,
  {
    id: 8,
    cat: "Balers",
    name: " Round Bale Wrapper",
    image: "/images/roundbalewrapper.webp",
    link: "https://www.rovic.com/agricultural-machinery/balers-and-bale-handling/bale-handling-equipment/round-bale-wrapper",
  },
  {
    id: 9,
    cat: "Wraps",
    name: "Slide Wraps ",
    image: "/images/slidewrap.jpg",
    link: "https://www.rovic.com/mechanisation-supplies/net-wrap-and-twine/slide-wraps",
  },
  {
    id: 10,
    cat: "Wraps",
    name: "Baler Twine",
    image: "/images/balertwine.jpg",
    link: "https://www.rovic.com/mechanisation-supplies/net-wrap-and-twine/baler-twine",
  },
  {
    id: 11,
    cat: "Balers",
    name: "Bale Loader Implements ",
    image: "/images/baleloaderimplements.jpg",
    link: "https://www.rovic.com/agricultural-machinery/handling-equipment/loader-attachments/bale-loader-implements",
  },
  {
    id: 8,
    cat: "Wraps",
    name: "Krone Baler Net ",
    image: "/images/kronebalernet.jpg",
    link: "https://www.rovic.com/mechanisation-supplies/net-wrap-and-twine/krone-baler-net",
  }
]

const feederMixers = [
  {
    id: 1,
    cat: "Feeder Mixers",
    name: "Trailed Horizontal Feeder Mixer",
    image: "/images/trailed-horizontal-feeder-mixer-wagon.webp",
    link: "https://www.rovic.com/agricultural-machinery/feeder-mixers/trailed-feeder-mixers/trailed-horizontal-feeder-mixer",
  },
  {
    id: 2,
    cat: "Feeder Mixers",
    name: "Trailed Vertical Feeder Mixer",
    image: "/images/trailed-feeder-mixer.webp",
    link: "https://www.rovic.com/agricultural-machinery/feeder-mixers/trailed-feeder-mixers/trailed-vertical-feeder-mixer",
  }
]
const forageHarvesters = [
  {
    id: 1,
    cat: "Forage Harvesters",
    name: "Self Propelled Forage Harvester",
    image: "/images/self-propelled-forwage-harvester.webp",
    link: "https://www.rovic.com/agricultural-machinery/forage-harvesters/self-propelled-forage-harvester",
  },
  {
    id: 2,
    cat: "Forage Harvesters",
    name: "3-Point Forage Harvester",
    image: "/images/3-pt-forage-harvester.webp",
    link: "https://www.rovic.com/agricultural-machinery/forage-harvesters/3-point-forage-harvester",
  }
]

const groundEngaging = [
  {
    id: 1,
    cat: "Ground Engaging",
    name: "Wajanja Small Conservation Chisel Plough",
    image: "/images/wajanja-3.jpg",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/chisel-plough/wajanja-small-conservation-chisel-plough",
  },
  {
    id: 2,
    cat: "Ground Engaging",
    name: "DLB12 Chisel Ploughs",
    image: "/images/dlb12-chidel-plows.jpg",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/chisel-plough/dlb12-chisel-ploughs",
  },
  {
    id: 3,
    cat: "Ground Engaging",
    name: "Heavy Duty Offset Disc Harrows",
    image: "/images/heavydutychiselplow.jpg",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/ploughs-offset-disc-harrows/heavy-duty-offset-disc-harrows",
  },
  {
    id: 4,
    cat: "Ground Engaging",
    name: "Offset & Poly Disc Harrows",
    image: "/images/offset-disc-harrow.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/ploughs-offset-disc-harrows/offset-poly-disc-harrows",
  },
  {
    id: 5,
    cat: "Ground Engaging",
    name: "3-Point Mould Board Plough",
    image: "/images/3-pt-mould-board-plough.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/ploughs-offset-disc-harrows/3-point-mould-board-plough",
  }
  ,
  {
    id: 6,
    cat: "Ground Engaging",
    name: "3-Point Disc Plough",
    image: "/images/rovic-3-pt-disc-plough.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/ploughs-offset-disc-harrows/3-point-disc-plough",
  },
  {
    id: 7,
    cat: "Ground Engaging",
    name: "3-Point Trash Handicult",
    image: "/images/trash-handicult.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/soil-cultivators/3-point-trash-handicult",
  },
  {
    id: 8,
    cat: "Harrow",
    name: "Heavy Duty Power Harrow",
    image: "/images/heavydutypowerharrow.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/power-tillers-harrows/heavy-duty-power-harrow",
  },
  {
    id: 9,
    cat: "Harrow",
    name: "Medium Duty Power Harrow",
    image: "/images/mediumdutyharrow.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/power-tillers-harrows/medium-duty-power-harrow",
  },
  {
    id: 10,
    cat: "Tiller",
    name: "Medium Duty Rotary Tiller",
    image: "/images/mediumrotarytiller.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/power-tillers-harrows/medium-duty-rotary-tiller",
  },
  {
    id: 11,
    cat: "Harrow",
    name: "High Speed Disc Harrow",
    image: "/images/highspeeddischarrow.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/soil-cultivators/high-speed-disc-harrow",
  },
  {
    id: 12,
    cat: "Tiller",
    name: "Pasture Renovator",
    image: "/images/pasture-renovater.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/pasture-crop-renovaters/pasture-renovator",
  },
  {
    id: 13,
    cat: "Ripper",
    name: "Super 25 - 32",
    image: "/images/super2532.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/rippers/super-25-32",
  },
  {
    id: 14,
    cat: "Ripper",
    name: "Super 19 Rippers ",
    image: "/images/super19.jpg",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/rippers/super-19-rippers",
  }
  ,{
    id: 15,
    cat: "Tiller",
    name: "Heavy Duty Rotary Tillers",
    image: "/images/heavydutyrotarytiller.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/power-tillers-harrows/heavy-duty-rotary-tillers",
  },
  {
    id: 16,
    cat: "Cultivator",
    name: "Rotary Hoe's ",
    image: "/images/rotary-hoes.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/soil-cultivators/rotary-hoe-s",
  },
  {
    id: 17,
    cat: "Cultivator",
    name: "Crumble Land Rollers",
    image: "/images/crumbleroller.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/rollers-and-headstocks/crumble-land-rollers",
  },
  {
    id: 18,
    cat: "Cultivator",
    name: "Optimer Stubble Cultivator",
    image: "/images/optimer-conventionnal-tillage.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/soil-cultivators/optimer-stubble-cultivator",
  },
  {
    id: 19,
    cat: "Cultivator",
    name: "Trailed Cultivators",
    image: "/images/trailed-cultivators.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/soil-cultivators/trailed-cultivators",
  },
  {
    id: 20,
    cat: "Roller",
    name: "Seedbed Rollers",
    image: "/images/seedbed-roller.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/rollers-and-headstocks/seedbed-rollers",
  },
  {
    id: 21,
    cat: "Cultivator",
    name: " Cultivator Tines ",
    image: "/images/cultivator-tines.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/soil-cultivators/cultivator-tines",
  },
  {
    id: 23,
    cat: "Ripper",
    name: "Crop Renovator (In-Line Ripper) ",
    image: "/images/crripper.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/pasture-crop-renovaters/crop-renovator-in-line-ripper",
  },
  {
    id: 24,
    cat: "Cultivator",
    name: "Excelerator Vertical Cultivator",
    image: "/images/execlerator-vertical-cultivator.webp",
    link: "https://www.rovic.com/agricultural-machinery/ground-engaging/soil-cultivators/excelerator-vertical-cultivator",
  }
]

function Rovic() {

  return (
  <main class="container my-5">
  <section class="mb-5"> 
    <div class="head-img container-fluid">
      <img src="images/forestry/h424-1170g-uphill-1366x768.avif" class="img-fluid" alt="Image"/>
      <p>We stock genuine Rovic Products.
      </p>
    </div>

    <div class="container-fluid text-center">
    <ProductGrid products={forageHarvesters}/>
    <ProductGrid products={rovicProducts_baler}/>
    <ProductGrid products={rovicProducts_bale}/>
    <ProductGrid products={feederMixers}/>
    <ProductGrid products={groundEngaging}/>
    </div>

    <div class="container-fluid">
      <img src="images/forestry/1470g_ch9boom_large.avif" class="img-fluid" alt="Forestry Equipment Banner"/>
      <p>Our advanced forestry machinery is engineered for maximum productivity in tough environments. From timber
        harvesting to transportation, our equipment supports every step of the process.</p>
    </div>

    <br />

    {/* <!-- Product links --> */}
    <ProductList data={[rovicProducts_bale, rovicProducts_baler, forageHarvesters, feederMixers, groundEngaging]}/>
    </section>
    </main>
    );
    }
export default Rovic;