import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const FirebaseProvider = ({ children, firebaseConfig }) => {
  const [app, setApp] = useState(null);
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    if (!app) {
      const newApp = initializeApp(firebaseConfig);
      setApp(newApp);
      
      const auth = getAuth(newApp);
      setAuth(auth);

      return () => {
        if (auth) {
          auth.signOutWithEmailAndPassword();
        }
      };
    }
  }, []);

  return (
    <div>
      {children}
    </div>
  );
};

export default FirebaseProvider;
