import React from "react";
import Layout from "../templates_/Layout";
import Contact from "./Contact";
import SupplierRibbon from "../components/SupplierRibbon";

const ContactUs = () => {
  return (
    <Layout>
      <main className="container my-5">
        
      </main>
    </Layout>
  );
};

export default ContactUs;
