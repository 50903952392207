import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../index.css";

function Header() {
  const location = useLocation();
  const isActive = (path) =>
    location.pathname === path ? "nav-link active" : "nav-link";

  const [openDropdown, setOpenDropdown] = useState(null);
  const [openSubDropdown, setOpenSubDropdown] = useState(null);

  const handleDropdown = (menu, event) => {
    if (event) event.preventDefault();
    setOpenDropdown(openDropdown === menu ? null : menu);
    if (menu !== "agriculture") setOpenSubDropdown(null); // Reset sub-dropdown when switching
  };

  const handleSubDropdown = (submenu, event) => {
    if (event) event.preventDefault();
    setOpenSubDropdown(openSubDropdown === submenu ? null : submenu);
  };

  const handleLinkClick = () => {
    setOpenDropdown(null);
    setOpenSubDropdown(null);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow p-2">
      <div className="container-fluid d-flex flex-column align-items-center">
        <a className="navbar-brand cutout" href="/">
          <img
            src="../images/2024_website_logo_large_transparent.png"
            width="100%"
            height="100%"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </a>

        <button
          className="navbar-toggler w-40 mt-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-expanded="false"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <Link className={isActive("/")} to="/">
                Home
              </Link>
            </li>

            {/* Products Dropdown */}
            <li
              className="nav-item dropdown"
              onMouseEnter={() => setOpenDropdown("products")}
              onMouseLeave={() => setOpenDropdown(null)}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#"
                onClick={(e) => handleDropdown("products", e)}
              >
                Products
              </a>
              <ul
                className={`dropdown-menu ${
                  openDropdown === "products" ? "show" : ""
                }`}
              >
                {/* Agriculture Submenu */}
                <li
                  className="dropdown-submenu"
                  onMouseEnter={() => setOpenSubDropdown("agriculture")}
                  onMouseLeave={() => setOpenSubDropdown(null)}
                >
                  <a
                    className="dropdown-item dropdown-toggle"
                    href="#"
                    onClick={(e) => handleSubDropdown("agriculture", e)}
                  >
                    Agriculture
                  </a>
                  <ul
                    className={`dropdown-menu ${
                      openSubDropdown === "agriculture" ? "show" : ""
                    }`}
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/products/agTractors"
                        onClick={handleLinkClick}
                      >
                        Tractors
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/products/agHarvesters"
                        onClick={handleLinkClick}
                      >
                        Harvesters
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/products/agImplements"
                        onClick={handleLinkClick}
                      >
                        Implements
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/products/precisionag"
                        onClick={handleLinkClick}
                      >
                        Precision Agriculture
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="/products/lawn-garden"
                    onClick={handleLinkClick}
                  >
                    Lawn & Garden
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/products/turf"
                    onClick={handleLinkClick}
                  >
                    Turf
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/products/construction"
                    onClick={handleLinkClick}
                  >
                    Construction
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/products/forestry"
                    onClick={handleLinkClick}
                  >
                    Forestry
                  </Link>
                </li>
              </ul>
            </li>

            {/* Parts & Service Dropdown */}
            <li
              className="nav-item dropdown"
              onMouseEnter={() => setOpenDropdown("service")}
              onMouseLeave={() => setOpenDropdown(null)}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#"
                onClick={(e) => handleDropdown("service", e)}
              >
                Parts & Service
              </a>
              <ul
                className={`dropdown-menu ${
                  openDropdown === "service" ? "show" : ""
                }`}
              >
                <li>
                  <Link className="dropdown-item" to="/service/sales-support">
                    Sales Support
                  </Link>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://partscatalog.deere.com/jdrc/"
                  >
                    Parts Lookup
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link className={isActive("/news")} to="/news">
                News
              </Link>
            </li>
            <li className="nav-item">
              <Link className={isActive("/about")} to="/about">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className={isActive("/contact")} to="/contact">
                Contact
              </Link>
            </li>
          </ul>

          {/* Social Icons */}
          <ul className="navbar-nav ms-auto d-flex flex-row align-items-center gap-3">
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://maps.app.goo.gl/GxcHXv2VK3YFkfyK7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-map-marker-alt"></i>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="mailto:sales@swazitrac.co.sz">
                <i className="fas fa-envelope"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
