import React from "react";
import { useKeenSlider } from "keen-slider/react";
import { Link } from "react-router-dom";

import "keen-slider/keen-slider.min.css";
import "../index.css";

export default function Slider({ prodimg }) {
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: 4, // Default for desktops
      spacing: 15,
    },
    loop: true,
    mode: "free-snap",
    breakpoints: {
      "(max-width: 768px)": {
        slides: { perView: 1, spacing: 10 }, // Mobile: 1 item
      },
      "(max-width: 1024px)": {
        slides: { perView: 2, spacing: 10 }, // Tablet: 2 items
      },
      "(min-width: 1025px)": {
        slides: { perView: 4, spacing: 15 }, // Desktop: 4 items
      },
    },
  });

  return (
    <div ref={sliderRef} className="keen-slider product-slider">
      {prodimg.map((prod, index) => (
        <Link to={prod.link} key={index} className="keen-slider__slide product-slide">
          <div className="card product-card text-center">
            <div className="product-image-wrapper">
              <img src={prod.url} className="img-fluid product-image" alt={prod.Title} />
            </div>
            <h5 className="product-title">{prod.Title}</h5>
          </div>
        </Link>
      ))}
    </div>
  );
}
